<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_affiliationList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <!-- １行目スタート -->
          <v-row class="search-row">
            <!-- 所属コード -->
            <div class="search-textbox first-search-item" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="affiliationCode"
                :label="$t('label.lbl_affiliationCode')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="50"
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 所属名 -->
            <div class="serch-textbox-itemCnCd" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="affiliationName"
                :label="$t('label.lbl_affiliationName')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="100"
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 所属名カナ -->
            <div class="serch-textbox-itemCnCd" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="affiliationNameKana"
                :label="$t('label.lbl_affiliationNameKana')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="100"
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 所属名略称 -->
            <div class="serch-textbox-useritemCd" style="float: left">
              <v-text-field
                dense
                outlined
                v-model="affiliationNameAbbreviation"
                :label="$t('label.lbl_affiliationNameAbbreviation')"
                persistent-hint
                clear-icon="mdi-close-circle"
                clearable
                maxlength="20"
              >
              </v-text-field>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 基準日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="referenceDate"
                class="txt-single date-style"
                :label="$t('label.lbl_refernceDate')"
                @change="changeDate"
                :rules="[rules.inputRequired, rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="referenceDateCal" @input="dateMenu = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <div class="search-row-exeBtn">
              <!--ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="get-search"
                  class="api-btn"
                  @click="getAffiliationList('get-search')"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area">
                <!--新規登録ボタン-->
                <v-btn color="primary" id="btn-search" class="api-btn" @click="initRegist">{{
                  $t("btn.btn_iniRegist")
                }}</v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span>総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>
                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_10_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_10_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_10_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :options.sync="sortOptions"
          :hide-default-footer="true"
          height="530px"
        >
          <!-- 所属コード・所属名 -->
          <template v-slot:[`item.affiliationCode`]="{ item }">
            <div v-if="item.affiliationName.length > 35">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 460px" v-bind="attrs" v-on="on">
                    {{ item.affiliationCode }}<br />
                    {{ item.affiliationName }}
                  </div>
                </template>
                <div>{{ item.affiliationCode }}<br />{{ item.affiliationName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              {{ item.affiliationCode }}<br />
              {{ item.affiliationName }}
            </div>
          </template>
          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="openUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- 設定ボタン押下後エラー時出現エラー -->
        <v-dialog v-model="copyDialogMsg" :max-width="800">
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ alertMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="okClicked"> OK </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
      />
      <CheckDialog
        :isShow.sync="checkDialog.isOpen"
        :message="checkDialog.message"
        :screenFlag="checkDialog.screenFlag"
        :okAction="checkDialog.okAction"
        :redMessage="checkDialog.redMessage"
        :changeFlag="checkDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import CheckDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_MST_020,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    CheckDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    //所属コード
    affiliationCode: "",
    // 所属名
    affiliationName: "",
    // 所属名カナ
    affiliationNameKana: "",
    // 所属名略称
    affiliationNameAbbreviation: "",
    // 基準日
    referenceDate: "",
    referenceDateCal: "",
    dateMenu: false,

    //検索条件保存
    affiliationCodeSearch: "",
    affiliationNameSearch: "",
    affiliationNameKanaSearch: "",
    affiliationNameAbbreviationSearch: "",
    referenceDateSearch: "",

    // チェックボタンチェック後ダイアログ
    checkDialogMessage: false,
    checkDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // 初期データ
    defaultData: [],
    u_10: false,
    // 検索ボタン押下後、trueにする
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [100, 250, 500],
    page: 1,
    pageCount: 1,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // メニュー
    openMenu: null,
    // テーブルフッター項目
    inputList: [],
    // コピー時アラートダイアログ
    copyDialogMsg: false,
    alertMessage: "",
    // ソート
    sortItem: "",
    sortOptions: {},
    // テーブルヘッダ項目
    headerItems: [
      // 所属コード
      {
        text: `${i18n.tc("label.lbl_affiliationCode")}/${i18n.tc("label.lbl_affiliationName")}`,
        value: "affiliationCode",
        width: "25%",
        align: "left",
        sortable: false,
      },
      // 所属名カナ
      {
        text: i18n.tc("label.lbl_affiliationNameKana"),
        value: "affiliationNameKana",
        width: "25%",
        align: "left",
        sortable: true,
      },
      // 所属名略称
      {
        text: i18n.tc("label.lbl_affiliationNameAbbreviation"),
        value: "affiliationNameAbbreviation",
        width: "21%",
        align: "left",
        sortable: true,
      },
      // 有効開始日
      {
        text: i18n.tc("label.lbl_validStartDateAndTime"),
        value: "validFrom",
        width: "12%",
        align: "center",
        sortable: true,
      },
      // 有効終了日
      {
        text: i18n.tc("label.lbl_validEndDateAndTime"),
        value: "validTo",
        width: "12%",
        align: "center",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        align: "center",
        width: "5%",
      },
    ],
  }),
  methods: {
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 100;
      this.getAffiliationList("get-search");
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.getAffiliationList("get-search");
    },

    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      //基準日にシステム日付をセット
      this.referenceDate = dateTimeHelper.convertJST().substr(0, 10);

      this.referenceDateCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

      //遷移元画面からの検索条件を取得
      if (this.$route.params.searchFilter) {
        this.affiliationCode = this.$route.params.searchFilter.affiliationCode;
        this.affiliationName = this.$route.params.searchFilter.affiliationName;
        this.affiliationNameKana = this.$route.params.searchFilter.affiliationNameKana;
        this.affiliationNameAbbreviation =
          this.$route.params.searchFilter.affiliationNameAbbreviation;
        if (this.$route.params.searchFilter.referenceDate) {
          this.referenceDate = this.$route.params.searchFilter.referenceDate;
          this.referenceDateCal = dateTimeHelper.dateCalc(
            dateTimeHelper.convertUTC2JST(this.referenceDate)
          );
        }
        this.getAffiliationList();
      }
    },
    // 詳細画面へ
    openUpdateDelete(item) {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_019,
        params: {
          affiliationCode: this.affiliationCodeSearch,
          affiliationName: this.affiliationNameSearch,
          affiliationNameKana: this.affiliationNameKanaSearch,
          affiliationNameAbbreviation: this.affiliationNameAbbreviationSearch,
          referenceDate: this.referenceDateSearch,
          item: item,
          displayFlg: "2", //どこからの画面遷移か判別させるため
          // 検索条件
          searchFilter: this.searchFilter,
          belongSid: item.belongSid,
        },
      });
    },
    okClicked() {
      this.copyDialogMsg = false;
    },
    // 新規登録ボタン押下
    initRegist() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_MST_019,
        params: {
          displayFlg: "1",
          // 検索条件
          searchFilter: this.searchFilter,
        },
      });
    },
    // 検索ボタン押下後、所属マスタ一覧を取得
    getAffiliationList() {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      this.inputList = [];
      if (
        this.$route.params.searchFilter &&
        Object.keys(this.$route.params.searchFilter).length !== 0 &&
        this.$route.params.searchFilter.displayFlg === "2"
      ) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        inputCheckResult = true;
      } else {
        // 上記外の場合はチェックを行う。
        inputCheckResult = this.$refs.form.validate();
      }

      // 入力チェックを実施
      if (inputCheckResult) {
        // ローディング画面表示ON
        this.loadingCounter = 1;

        //パラメータ設定
        const config = this.$httpClient.createGetApiRequestConfig();
        if (this.affiliationCode) {
          config.params.affiliationCode = this.affiliationCode;
        }
        if (this.affiliationName) {
          config.params.officialName = this.affiliationName;
        }
        if (this.affiliationNameKana) {
          config.params.officialNameKana = this.affiliationNameKana;
        }
        if (this.affiliationNameAbbreviation) {
          config.params.officialNameShort = this.affiliationNameAbbreviation;
        }
        config.params.referenceDate = dateTimeHelper.convertUTC(
          this.referenceDate + " 23:59:59.999"
        );
        config.params.searchKbn = "2";

        //検索条件保存
        this.affiliationCodeSearch = this.affiliationCode;
        this.affiliationNameSearch = this.affiliationName;
        this.affiliationNameKanaSearch = this.affiliationNameKana;
        this.affiliationNameAbbreviationSearch = this.affiliationNameAbbreviation;
        this.referenceDateSearch = this.referenceDate;

        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .secureGet(appConfig.API_URL.MST_BELONG_LIST, config)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 正常時
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                jsonData.resIdv.belongList.forEach((row) => {
                  this.inputList.push({
                    belongSid: row.belongSid,
                    affiliationCode: row.affiliationCode,
                    affiliationName: row.officialName,
                    affiliationNameKana: row.officialNameKana,
                    affiliationNameAbbreviation: row.officialNameShort,
                    validFrom: dateTimeHelper.convertUTC2JST(row.validFrom).substr(0, 10),
                    validTo: dateTimeHelper.convertUTC2JST(row.validTo).substr(0, 10),
                  });
                  this.totalCount = commonUtil.formatToCurrency(
                    Number(jsonData.resCom.resComCount)
                  );
                });
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.referenceDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.referenceDateCal = null;
      }
    },
    // カレンダー
    prevDate() {
      if (this.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.referenceDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.referenceDateCal != toDate) {
        this.referenceDateCal = toDate;
      } else {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDate() {
      if (this.referenceDateCal == null) {
        return;
      }
      let date = new Date(this.referenceDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.referenceDateCal != toDate) {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.referenceDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.checkDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.checkDialog.isOpen = true;
      this.checkDialog.screenFlag = true;
      this.checkDialog.changeFlag = false;
      this.checkDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      this.referenceDate = "";
      this.referenceDateCal = "";
      if (this.$route.params.searchFilter) {
        this.$route.params.searchFilter = null;
      }
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    isMenu(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    referenceDateCal(val) {
      this.referenceDate = this.formatDate(val);
    },
    page: function (newValue) {
      this.page = newValue;
      this.getItemList("");
    },
    sortOptions: {
      handler(items) {
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_MST_007_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getItemList();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.overflow {
  overflow: hidden;
}

.toatlNum {
  margin-left: 10px;
}

.txt-center {
  margin: auto;
}

.link {
  text-decoration: none;
}
</style>
